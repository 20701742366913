var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Web3Provider } from '@ethersproject/providers';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import networks from '@snapshot-labs/snapshot.js/src/networks.json';
import store from '@/store';
import { formatUnits } from '@ethersproject/units';
import { getProfiles } from '@/helpers/profile';
var wsProvider;
var auth;
var defaultNetwork = process.env.VUE_APP_DEFAULT_NETWORK || Object.keys(networks)[0];
if (wsProvider) {
    wsProvider.on('block', function (blockNumber) {
        store.commit('GET_BLOCK_SUCCESS', blockNumber);
    });
}
var state = {
    account: null,
    name: null,
    network: networks[defaultNetwork]
};
var mutations = {
    HANDLE_CHAIN_CHANGED: function (_state, chainId) {
        if (!networks[chainId]) {
            networks[chainId] = __assign(__assign({}, networks[defaultNetwork]), { chainId: chainId, name: 'Unknown', network: 'unknown', unknown: true });
        }
        _state.network = networks[chainId];
        console.debug('HANDLE_CHAIN_CHANGED', chainId);
    },
    WEB3_SET: function (_state, payload) {
        Object.keys(payload).forEach(function (key) {
            _state[key] = payload[key];
        });
    }
};
var actions = {
    login: function (_a, connector) {
        var dispatch = _a.dispatch, commit = _a.commit;
        if (connector === void 0) { connector = 'injected'; }
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        auth = getInstance();
                        commit('SET', { authLoading: true });
                        return [4 /*yield*/, auth.login(connector)];
                    case 1:
                        _b.sent();
                        if (!auth.provider.value) return [3 /*break*/, 3];
                        auth.web3 = new Web3Provider(auth.provider.value);
                        return [4 /*yield*/, dispatch('loadProvider')];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        commit('SET', { authLoading: false });
                        return [2 /*return*/];
                }
            });
        });
    },
    logout: function (_a) {
        var commit = _a.commit;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                auth = getInstance();
                auth.logout();
                commit('WEB3_SET', { account: null, profile: null });
                return [2 /*return*/];
            });
        });
    },
    loadProvider: function (_a) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(void 0, void 0, void 0, function () {
            var network, accounts, e_1, account, profiles, e_2;
            var _b;
            var _c, _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _f.trys.push([0, 6, , 7]);
                        if (auth.provider.value.removeAllListeners &&
                            !auth.provider.value.isTorus)
                            auth.provider.value.removeAllListeners();
                        if (auth.provider.value.on) {
                            auth.provider.value.on('chainChanged', function (chainId) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    commit('HANDLE_CHAIN_CHANGED', parseInt(formatUnits(chainId, 0)));
                                    return [2 /*return*/];
                                });
                            }); });
                            auth.provider.value.on('accountsChanged', function (accounts) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(accounts.length !== 0)) return [3 /*break*/, 2];
                                            commit('WEB3_SET', { account: accounts[0] });
                                            return [4 /*yield*/, dispatch('loadProvider')];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                            // auth.provider.on('disconnect', async () => {});
                        }
                        console.log('Provider', auth.provider.value);
                        network = void 0, accounts = void 0;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Promise.all([
                                auth.web3.getNetwork(),
                                auth.web3.listAccounts()
                            ])];
                    case 2:
                        _b = _f.sent(), network = _b[0], accounts = _b[1];
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _f.sent();
                        console.log(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        console.log('Network', network);
                        console.log('Accounts', accounts);
                        commit('HANDLE_CHAIN_CHANGED', network.chainId);
                        account = accounts.length > 0 ? accounts[0] : null;
                        return [4 /*yield*/, getProfiles([account])];
                    case 5:
                        profiles = _f.sent();
                        commit('WEB3_SET', {
                            account: account,
                            walletConnectType: ((_e = (_d = (_c = auth.provider.value) === null || _c === void 0 ? void 0 : _c.wc) === null || _d === void 0 ? void 0 : _d.peerMeta) === null || _e === void 0 ? void 0 : _e.name) || 'unknown',
                            profile: profiles[account]
                        });
                        return [3 /*break*/, 7];
                    case 6:
                        e_2 = _f.sent();
                        commit('WEB3_SET', { account: null, profile: null });
                        return [2 /*return*/, Promise.reject(e_2)];
                    case 7: return [2 /*return*/];
                }
            });
        });
    }
};
export default {
    state: state,
    mutations: mutations,
    actions: actions
};
