var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { getScoresDirectBatchWrap } from '@snapshot-labs/snapshot.js/src/utils';
import { formatProposals } from '@/helpers/utils';
import { getProfiles } from '@/helpers/profile';
import getProvider from '@snapshot-labs/snapshot.js/src/utils/provider';
import client from '@/helpers/client';
import { apolloClient } from '@/apollo';
import { VOTES_QUERY, PROPOSAL_QUERY } from '@/helpers/queries';
import { cloneDeep } from 'lodash';
import voting from '@/helpers/voting';
export function getProposal(space, id) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var response, _b, proposalQueryResponse, votes, proposal, e_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    console.time('getProposal.data');
                    return [4 /*yield*/, Promise.all([
                            apolloClient.query({
                                query: PROPOSAL_QUERY,
                                variables: {
                                    id: id
                                }
                            }),
                            apolloClient.query({
                                query: VOTES_QUERY,
                                variables: {
                                    id: id
                                }
                            })
                        ])];
                case 1:
                    response = _c.sent();
                    console.timeEnd('getProposal.data');
                    _b = cloneDeep(response), proposalQueryResponse = _b[0], votes = _b[1];
                    proposal = proposalQueryResponse.data.proposal;
                    if ((_a = proposal === null || proposal === void 0 ? void 0 : proposal.plugins) === null || _a === void 0 ? void 0 : _a.daoModule) {
                        // The Dao Module has been renamed to SafeSnap
                        // Previous proposals have to be renamed
                        proposal.plugins.safeSnap = proposal.plugins.daoModule;
                        delete proposal.plugins.daoModule;
                    }
                    return [2 /*return*/, {
                            proposal: proposal,
                            votes: votes.data.votes
                        }];
                case 2:
                    e_1 = _c.sent();
                    console.log(e_1);
                    return [2 /*return*/, e_1];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getResults(space, proposal, votes) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var provider, voters, strategies_1, _b, scores_1, profiles_1, votingClass, results, e_2;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    provider = getProvider(space.network);
                    voters = votes.map(function (vote) { return vote.voter; });
                    strategies_1 = (_a = proposal.strategies) !== null && _a !== void 0 ? _a : space.strategies;
                    /* Get scores */
                    console.time('getProposal.scores');
                    return [4 /*yield*/, Promise.all([
                            getScoresDirectBatchWrap(space.key, strategies_1, space.network, provider, voters, parseInt(proposal.snapshot)),
                            getProfiles(__spreadArrays([proposal.author], voters))
                        ])];
                case 1:
                    _b = _c.sent(), scores_1 = _b[0], profiles_1 = _b[1];
                    console.timeEnd('getProposal.scores');
                    console.log('Scores', scores_1);
                    proposal.profile = profiles_1[proposal.author];
                    votes.map(function (vote) { return (vote.profile = profiles_1[vote.voter]); });
                    votes = votes
                        .map(function (vote) {
                        vote.scores = strategies_1.map(function (strategy, i) { return scores_1[i][vote.voter] || 0; });
                        vote.balance = vote.scores.reduce(function (a, b) { return a + b; }, 0);
                        return vote;
                    })
                        .sort(function (a, b) { return b.balance - a.balance; })
                        .filter(function (vote) { return vote.balance > 0; });
                    votingClass = new voting[proposal.type](proposal, votes, strategies_1);
                    results = {
                        resultsByVoteBalance: votingClass.resultsByVoteBalance(),
                        resultsByStrategyScore: votingClass.resultsByStrategyScore(),
                        sumBalanceAllVotes: votingClass.sumBalanceAllVotes()
                    };
                    return [2 /*return*/, { votes: votes, results: results }];
                case 2:
                    e_2 = _c.sent();
                    console.log(e_2);
                    return [2 /*return*/, e_2];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getPower(space, address, proposal) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
        var strategies, scores, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    strategies = (_a = proposal.strategies) !== null && _a !== void 0 ? _a : space.strategies;
                    return [4 /*yield*/, getScoresDirectBatchWrap(space.key, strategies, space.network, getProvider(space.network), [address], parseInt(proposal.snapshot))];
                case 1:
                    scores = _b.sent();
                    scores = scores.map(function (score) {
                        return Object.values(score).reduce(function (a, b) { return a + b; }, 0);
                    });
                    return [2 /*return*/, {
                            scores: scores,
                            totalScore: scores.reduce(function (a, b) { return a + b; }, 0)
                        }];
                case 2:
                    e_3 = _b.sent();
                    console.log(e_3);
                    return [2 /*return*/, e_3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getProposals(space) {
    return __awaiter(this, void 0, void 0, function () {
        var proposals, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, client.getProposals(space.key)];
                case 1:
                    proposals = _a.sent();
                    return [2 /*return*/, formatProposals(proposals)];
                case 2:
                    e_4 = _a.sent();
                    console.log(e_4);
                    return [2 /*return*/, e_4];
                case 3: return [2 /*return*/];
            }
        });
    });
}
